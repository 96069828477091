import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { LoadingButton } from '@mui/lab';
import { Button, styled } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { countries } from '../../../countries';
import { Barcode, IngredientPositionOnProduct, Product, ProductCategory, ProductPrice, RetailPrice } from '../../../global';
import { createProduct, deleteProductById, getProductById, updateProductById } from '../../../setup/api/product';
import { getProductCategoriesDistinctOrdered, getProductCategoryByName } from '../../../setup/api/productCategory';
import { getRetailerList } from '../../../setup/api/retail';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { Retailer } from '../../../setup/models';
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import SelectField from '../../../ui/components/FieldSelect/FieldSelectWithoutFormix';
import { ComparLightChip } from '../../../ui/components/TagChips/ComparLightChip';
import { ComparTextField } from '../../../ui/components/TextField/ComparTextField';
import { TitleHeading } from '../../../ui/components/Titles/Title';
import { comparColors } from '../../../ui/helpers/colors';
import { parseCapitalStringToReadable, parseProductCategoryBack } from "../../../ui/helpers/stringModifiers";

interface CategoryItem {
    compar_name: string;
}

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const AddProductPage: FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { request, isLoading, abortController } = useFetch();
    const update = useFetchWithAlert(alertPayload(`Product Updated `));
    const create = useFetchWithAlert(alertPayload(`Product Created `));
    const deleted = useFetchWithAlert(alertPayload(`Product Deleted `));
    const [product, setProduct] = useState<Partial<Product>>({});
    const [ingredientString, setIngredientString] = useState<string>();
    const [retailPrices, setRetailPrices] = useState<RetailPrice[]>([]);
    const [productPrices, setProductPrices] = useState<ProductPrice[]>([]);
    const [selectedProductCategoy, setSelectedProductCategoy] = useState<string>(``);
    const [productCategory, setProductCategory] = useState<Partial<ProductCategory>>();
    const [productBarcodes, setProductBarcodes] = useState<Partial<Barcode>[] | undefined>();
    const [newBarcodeValue, setNewBarcodeValue] = useState<string>();

    const [retailersList, setRetailersList] = useState<Retailer[]>([]);
    const [uniqueRetailerNamesList, setUniqueRetailerNamesList] = useState<string[]>([]);

    const [showAll, setShowAll] = useState(false);

    const [productCategories, setProductCategories] = useState<string[]>([]);

    const [requestError, setRequestError] = useState<string>();
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const getProduct = () => {
        setLoading(true);
        request(getProductById, id, abortController.signal).then(productResponse => {
            productResponse.retailPrices.sort((a: any, b: any) => {
                if (a.retail.name < b.retail.name) {
                    return -1;
                }
                if (a.retail.name > b.retail.name) {
                    return 1;
                }
                if (a.retail.country < b.retail.country) {
                    return -1;
                }
                if (a.retail.country > b.retail.country) {
                    return 1;
                }
                return 0;
            });
            setProduct(productResponse);
            setProductBarcodes(productResponse.barcodes);
            const ingredientStringText = setProductsToString(productResponse.productProportions);

            setIngredientString(ingredientStringText);
            initialLoadOfRetailPrices(productResponse.retailPrices);
            initialLoadOfProductPrices(productResponse.productPrices);

            setSelectedProductCategoy(parseCapitalStringToReadable(productResponse.productCategory.compar_name));

            setRequestError(``);
            setLoading(false);
        });
    };

    const updateProduct = (payload: Partial<Product>) => {
        cleansePayload(payload);

        update.request(updateProductById, id, payload)
            .then((response: any) => {
                if (response.status === 200) {
                    setRequestError(``);
                    getProduct();
                } else {
                    setRequestError(`Error message: ${response.message}, status code: ${response.status}`);
                }
            })
            .catch(error => {
                console.log(`Error making the request:`, error);
            });
    };

    const createNewProduct = (payload: Partial<Product>) => {
        cleansePayload(payload);

        create.request(createProduct, payload)
            .then((response: any) => {
                if (response.id) {
                    setRequestError(``);
                    history.push(`/product/${response.id}`);
                } else {
                    setRequestError(`Error message: ${response.message}, status code: ${response.status}`);
                }
            })
            .catch(error => {
                setRequestError(`Error message: ${error.message}, status code: ${error.status}`);
            });
    };

    const cleansePayload = (payload: Partial<Product>) => {
        delete payload.productProportions;

        const ingredientsArray = ingredientString?.split(`;`).filter(value => value.trim() !== ``);

        const ingredientsArrayPost: { ingredient: { name: string, position: number } }[] = [];

        if (ingredientsArray && ingredientsArray[0] !== ``) {
            for (let i = 0; i < ingredientsArray.length; i++) {
                ingredientsArrayPost.push({ ingredient: { name: ingredientsArray[i].trim(), position: (i + 1) } });
            }
            payload.productProportions = ingredientsArrayPost;
        } else {
            payload.productProportions = [];
        }

        delete payload.productPrices;
        payload.productPrices = productPrices;

        delete payload.productCategory;
        payload.productCategory = productCategory;

        delete payload.retailPrices;
        payload.retailPrices = retailPrices;

        if (payload.retailPrices && Array.isArray(payload.retailPrices)) {
            payload.retailPrices = payload.retailPrices.map(retailPrice => {
                if (retailPrice.retailer === `Lyko`) {
                    let market = ``;

                    switch (retailPrice.country) {
                        case `denmark`:
                            market = `DK`;
                            break;

                        case `norway`:
                            market = `NO`;
                            break;

                        case `netherlands`:
                            market = `NL`;
                            break;

                        case `germany`:
                            market = `DE`;
                            break;

                        case `sweden`:
                            market = `SE`;
                            break;

                        default:
                            market = ``;
                            break;
                    }
                    let newLink = retailPrice.link;
                    const marketRegex = /(\?|&)market=[^&]*/;

                    if (marketRegex.test(retailPrice.link)) {
                        if (market) {
                            // Replace existing market parameter
                            newLink = retailPrice.link.replace(marketRegex, `$1market=${market}`);
                        } else {
                            // Remove existing market parameter if market is empty
                            newLink = retailPrice.link.replace(marketRegex, ``);
                            // Clean up any dangling '&' or '?' if necessary
                            newLink = newLink.replace(/[?&]$/, ``);
                        }
                    } else if (market) {
                        // Append new market parameter if market is not empty
                        newLink = `${retailPrice.link}${retailPrice.link.includes(`?`) ? `&` : `?`}market=${market}`;
                    }

                    return {
                        ...retailPrice,
                        link: newLink
                    };
                }
                return retailPrice;
            });
        }

        if (productBarcodes) {
            payload.barcodes = productBarcodes;
        }
    };

    const setProductsToString = (ingredients: Partial<IngredientPositionOnProduct[]>) => {
        let ingredientString = ``;

        for (let i = 0; i < ingredients.length; i++) {
            if (ingredients[i]?.ingredient) {
                const ingredientName = ingredients[i]?.ingredient.name;

                if (i + 1 !== ingredients.length) {
                    ingredientString += ingredientName + `; `;
                } else {
                    ingredientString += ingredientName;
                }
            }
        }
        return ingredientString;
    };

    const addRetailer = () => {
        setShowAll(true);
        setRetailPrices(prevPrices => [
            ...prevPrices,
            { price: 0, currency: ``, affiliateLink: ``, link: ``, retailer: ``, country: ``, discountPrice: 0 }
        ]);
    };

    const addProductPrice = () => {
        setShowAll(true);
        setProductPrices(prevPrices => [
            ...prevPrices,
            { bigBuyId: ``, price: 0, recommendedRetailPrice: 0, reference: ``, distributorPrice: 0 }
        ]);
    };

    const deleteProduct = () => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text) === true) {
                deleted.request(deleteProductById, id, abortController.signal).then(deleteProduct => {
                    if (deleteProduct.status === 200) {
                        history.push(`/products`);
                    } else {
                        setRequestError(`Error message: ${deleteProduct.message}, status code: ${deleteProduct.status}`);
                    }
                });
            }
        }
    };

    const removeRetailer = (index: number) => {
        setRetailPrices(prevPrices => prevPrices.filter((_, i) => i !== index));
    };

    const handleRemoveBarcode = (index: number) => {
        if (productBarcodes) {
            const updatedBarcodes = productBarcodes.filter((_, i) => i !== index);

            setProductBarcodes(updatedBarcodes);
        }
    };

    const handleAddBarcode = () => {
        if (newBarcodeValue !== undefined && newBarcodeValue !== ``) {
            const newBarcode: Partial<Barcode> = {
                barcode: Number(newBarcodeValue),
            };

            const updatedBarcodes = productBarcodes ? [...productBarcodes, newBarcode] : [newBarcode];

            setProductBarcodes(updatedBarcodes);
            setNewBarcodeValue(``);
        }
    };

    const initialLoadOfRetailPrices = (retailPrices: any) => {
        const retailPricesArray: RetailPrice[] = [];

        if (retailPrices.length !== 0) {
            for (let i = 0; i < retailPrices.length; i++) {
                retailPricesArray.push({ price: retailPrices[i].price, currency: retailPrices[i].currency, affiliateLink: retailPrices[i].affiliateLink, link: retailPrices[i].link, retailer: retailPrices[i].retail.name, country: retailPrices[i].retail.country, discountPrice: retailPrices[i].discountPrice });
                setRetailPrices(retailPricesArray);
            }
        }
    };

    const initialLoadOfProductPrices = (productPrices: any) => {
        const productPricesArray: ProductPrice[] = [];

        if (productPrices.length !== 0) {
            for (let i = 0; i < productPrices.length; i++) {
                productPricesArray.push({ bigBuyId: productPrices[i].bigBuyId, price: productPrices[i].price, recommendedRetailPrice: productPrices[i].recommendedRetailPrice, reference: productPrices[i].reference, distributorPrice: productPrices[i].distributorPrice });
                setProductPrices(productPricesArray);
            }
        }
    };

    const setRetailPricesArray = (key: keyof RetailPrice, value: string, index: number) => {
        setRetailPrices(prevPrices => {
            const newRetailPrice = [...prevPrices];

            newRetailPrice[index] = {
                ...newRetailPrice[index],
                [key]: value,
            };
            return newRetailPrice;
        });
    };

    const setProductPricesArray = (key: keyof ProductPrice, value: string, index: number) => {
        setProductPrices(prevPrices => {
            const newProductPrice = [...prevPrices];

            newProductPrice[index] = {
                ...newProductPrice[index],
                [key]: value,
            };
            return newProductPrice;
        });
    };

    const renderRetailPriceFields = () => {
        const retailPricesToShow = showAll ? retailPrices : retailPrices.slice(0, 5);

        return retailPricesToShow.map((retail, index) => {
            let filteredCountries = countries;
            let defaultCurrency = ``;

            if (retail.retailer !== ``) {
                filteredCountries = countries.filter(country => {
                    const countryName = country.id.toLowerCase();

                    return retailersList.some(retailer =>
                        retailer.country.toLowerCase() === countryName && retailer.name === retail.retailer
                    );
                });
                if (!retail.country) {
                    const matchingRetailer = retailersList.find(retailer =>
                        retailer.name === retail.retailer
                    );

                    if (matchingRetailer && matchingRetailer.defaultCurrency) {
                        defaultCurrency = matchingRetailer.defaultCurrency;
                        retail.currency = defaultCurrency;
                    }
                }
            }

            const country = filteredCountries.find(country => country.id === retail.country);
            const countryValue = country ? { id: country.id, value: country.value, currency: country.currency } : { id: `null`, value: `No country`, currency: defaultCurrency };

            return (
                <Grid container justifyContent={`space-between`} alignItems={`center`}>
                    <Fragment key={index}>
                        <Grid xs={2}>
                            <SelectFieldFromArray<string>
                                name='retailer'
                                label='Retailer'
                                keyName='retailer'
                                sx={{
                                    backgroundColor: `#fff`,
                                    borderRadius: `20px`,
                                    'fieldset': {
                                        border: `none`,
                                    },
                                }}
                                onChange={(e: any) => {
                                    setRetailPricesArray(`retailer`, e.target.value, index);
                                }}
                                value={retail.retailer}
                                options={uniqueRetailerNamesList}
                                width={`85%`}
                                required
                            />
                        </Grid>
                        <Grid xs={2}>
                            <SelectField
                                name='country'
                                label='Country'
                                inputLabelStyle={{
                                    backgroundColor: `transparent`,
                                }}
                                style={{
                                    backgroundColor: comparColors.primary.light,
                                    border: `none`,
                                }}
                                value={countryValue}
                                onChange={value => {
                                    setRetailPricesArray(`country`, value?.id || `null`, index);
                                    setRetailPricesArray(`currency`, value?.currency || `DKK`, index);
                                }}
                                options={filteredCountries}
                                getOptionLabel={option => option.value}
                                width={`80%`}
                            />
                        </Grid>
                        <Grid xs={3}>
                            <ComparTextField
                                type='string'
                                label={`Link`}
                                sx={{
                                    maxWidth: `90%`
                                }}
                                InputLabelProps={{ shrink: true }}
                                placeholder='Link (without base url like "matas.dk")'
                                required={true}
                                value={retail.link}
                                onChange={e => setRetailPricesArray(`link`, e.target.value, index)}
                            />
                        </Grid>
                        <Grid xs={2}>
                            <ComparTextField
                                type='string'
                                label={`Recommended retail price`}
                                sx={{
                                    maxWidth: `90%`
                                }}
                                InputLabelProps={{ shrink: true }}
                                placeholder='Price in DKK'
                                required={true}
                                value={retail.price}
                                onChange={e => setRetailPricesArray(`price`, e.target.value, index)}
                            />
                        </Grid>
                        <Grid xs={2}>
                            <ComparTextField
                                type='string'
                                label={`Price (${retail.currency ? retail.currency : countryValue.currency}) - shown price`}
                                sx={{
                                    maxWidth: `90%`
                                }}
                                InputLabelProps={{ shrink: true }}
                                placeholder='Price in DKK'
                                required={true}
                                value={retail.discountPrice}
                                onChange={e => setRetailPricesArray(`discountPrice`, e.target.value, index)}
                            />
                        </Grid>
                        <Grid xs={1}>
                            <Button onClick={() => removeRetailer(index)} >
                                <HighlightOffIcon />
                            </Button>
                        </Grid>
                    </Fragment >
                </Grid >
            );
        });
    };

    const productPriceFields = () => {
        const productPricesToShow = showAll ? productPrices : productPrices.slice(0, 5);

        return productPricesToShow.map((productPrice, index) => (
            <Grid container justifyContent={`space-between`} alignItems={`center`}>
                <Fragment key={index}>
                    <Grid xs={4}>
                        <ComparTextField
                            type='string'
                            label={`Big Buy Id`}
                            sx={{
                                maxWidth: `90%`
                            }}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Big Buy Id'
                            required={true}
                            value={productPrice.bigBuyId}
                            onChange={e => setProductPricesArray(`bigBuyId`, e.target.value, index)}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <ComparTextField
                            type='string'
                            label={`Reference (SKU)`}
                            sx={{
                                maxWidth: `90%`
                            }}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Reference (SKU)'
                            required={true}
                            value={productPrice.reference}
                            onChange={e => setProductPricesArray(`reference`, e.target.value, index)}
                        />
                    </Grid>
                    <Grid xs={1.5}>
                        <ComparTextField
                            type='string'
                            label={`Price (EUR) - bought price`}
                            sx={{
                                maxWidth: `90%`
                            }}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Price in EUR'
                            required={true}
                            value={productPrice.price}
                            onChange={e => setProductPricesArray(`price`, e.target.value, index)}
                        />
                    </Grid>
                    <Grid xs={1.5}>
                        <ComparTextField
                            type='string'
                            label={`Recommended retail price (EUR)`}
                            sx={{
                                maxWidth: `90%`
                            }}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Price in EUR'
                            required={true}
                            value={productPrice.recommendedRetailPrice}
                            onChange={e => setProductPricesArray(`recommendedRetailPrice`, e.target.value, index)}
                        />
                    </Grid>
                    <Grid xs={1.5}>
                        <ComparTextField
                            type='string'
                            label={`Distributor price (EUR)`}
                            sx={{
                                maxWidth: `90%`
                            }}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Price in EUR'
                            required={true}
                            value={productPrice.distributorPrice}
                            onChange={e => setProductPricesArray(`distributorPrice`, e.target.value, index)}
                        />
                    </Grid>
                    <Grid xs={0.5}>
                        <ComparLightChip
                            style={{ fontSize: `13px` }}
                            onDelete={() => setProductPrices([])}
                            deleteIcon={<HighlightOffIcon color="inherit" />}
                            variant="filled"
                        />
                    </Grid>
                </Fragment >
            </Grid >
        ));
    };

    useEffect(() => {
        // request(getRetailerList).then(results => {
        //     if (results) {
        //         const [retailers] = results;

        //         const uniqueSortedRetailers = Array.from(
        //             new Map(retailers.map((retail: Retailer) => [retail.name, retail.name])).values()
        //         ).sort((a, b) => (a as string).localeCompare(b as string)) as string[];

        //         setRetailersList(retailers);
        //         setUniqueRetailerNamesList(uniqueSortedRetailers);
        //     }
        // });

        try {
            request(getProductCategoriesDistinctOrdered, abortController.signal)
                .then((categories: CategoryItem[]) => {
                    setProductCategories(categories.map(item => item.compar_name));
                })
                .catch(error => {
                    console.error(`Error fetching categories:`, error);
                });
        } catch (error) {
            console.error(`Failed to fetch product categories`, error);
        }
        if (id) {
            getProduct();
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Fragment>
            <Formik
                enableReinitialize
                onSubmit={values => {
                    if (id) {
                        return updateProduct(values);
                    }
                    return createNewProduct(values);
                }}
                initialValues={product}>
                {(props: FormikProps<Partial<Product>>) => (
                    <Form>
                        <Grid container justifyContent={`space-between`} >
                            <Grid xs={4}>
                                <ComparTextField
                                    type='string'
                                    placeholder='Name'
                                    label='Name'
                                    required={true}
                                    sx={{
                                        maxWidth: `90%`
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    {...props.getFieldProps(`name`)}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <ComparTextField
                                    type='string'
                                    label='Brand'
                                    required={true}
                                    sx={{
                                        maxWidth: `90%`
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Brand'
                                    {...props.getFieldProps(`brand`)}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <SelectFieldFromArray<string>
                                    name='productCategory'
                                    label='Product Category'
                                    required={true}
                                    keyName='productCategory'
                                    value={selectedProductCategoy}
                                    sx={{
                                        'fieldset': {
                                            border: `none`
                                        },
                                    }}
                                    onChange={(e: { target: { value: ProductCategory } }) => {
                                        setLoading(true);
                                        let string = e.target.value as any;

                                        string = parseProductCategoryBack(string);

                                        const joinedValue = string as ProductCategory;

                                        setSelectedProductCategoy(parseCapitalStringToReadable(string));

                                        request(getProductCategoryByName, joinedValue, abortController.signal)
                                            .then(productCategory => {
                                                if (productCategory.id) {
                                                    setProductCategory(productCategory);
                                                    setLoading(false);
                                                } else {
                                                    console.log(`Product Category not found`);
                                                }
                                            })
                                            .catch(error => {
                                                console.error(`Error fetching product category:`, error);
                                            });
                                    }}
                                    options={productCategories.map(parseCapitalStringToReadable)}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <ComparTextField
                                    type='string'
                                    label='Amount'
                                    required={true}
                                    sx={{
                                        maxWidth: `90%`
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Amount'
                                    {...props.getFieldProps(`amount`)}
                                />
                            </Grid>
                            <Grid xs={8}>
                                <ComparTextField
                                    type='string'
                                    label='Image URL'
                                    required={true}
                                    sx={{
                                        maxWidth: `100%`
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Image URL'
                                    {...props.getFieldProps(`imageUrl`)}
                                />
                            </Grid>
                            {/* {renderRetailPriceFields()} */}
                            {/* <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop={1} marginBottom={1}>
                                {retailPrices.length > 5 && (
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: `1.2em` }}
                                        onClick={() => setShowAll(!showAll)}
                                    >
                                        {showAll ? `Show Less` : `Show All`}
                                    </LoadingButton>
                                )}
                                <Button
                                    onClick={addRetailer}>
                                    Add Retailer
                                </Button>
                            </Grid> */}
                            {productPriceFields()}
                            <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop={1} marginBottom={1}>
                                {productPrices.length < 1 &&
                                    <Button
                                        onClick={addProductPrice}>
                                        Add Product Price
                                    </Button>
                                }
                            </Grid>
                            <Grid xs={12}>
                                <ComparTextField
                                    type='string'
                                    multiline
                                    placeholder='Description'
                                    required={true}
                                    label='Description'
                                    sx={{
                                        maxWidth: `100%`
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    {...props.getFieldProps(`description`)}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <ComparTextField
                                    type='string'
                                    multiline
                                    sx={{
                                        maxWidth: `100%`
                                    }}
                                    label='Ingredients (semicolon seperated)'
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Ingredients'
                                    value={ingredientString}
                                    onChange={(e: any) => setIngredientString(e.target.value)}
                                />
                            </Grid>
                            {id &&
                                <Grid xs={12}>
                                    <TagFormGrid>
                                        <TitleHeading children="Barcodes" style={{ fontSize: `1rem`, paddingBottom: `1em` }} />
                                        <Grid container spacing={1} style={{ display: `flex`, flexWrap: `wrap` }}>
                                            {productBarcodes && productBarcodes.map((barcode, index) => (
                                                <Grid item xs={1.9} key={index} style={{ marginRight: `8px`, marginBottom: `8px` }}>
                                                    <ComparLightChip
                                                        style={{ fontSize: `13px` }}
                                                        // onDelete={barcode.id ? () => handleRemoveBarcode(index) : undefined}
                                                        // deleteIcon={barcode.id ? <HighlightOffIcon color="inherit" /> : <p />}
                                                        onDelete={() => handleRemoveBarcode(index)}
                                                        deleteIcon={<HighlightOffIcon color="inherit" />}
                                                        label={barcode.barcode}
                                                        variant="filled"
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid item xs={3} style={{ display: `flex`, alignItems: `center`, marginRight: `8px` }}>
                                            <ComparTextField
                                                type="number"
                                                label='New barcode'
                                                style={{
                                                    backgroundColor: `#FFF`,
                                                    marginRight: `8px`,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                placeholder='New barcode'
                                                value={newBarcodeValue}
                                                onChange={e => setNewBarcodeValue(e.target.value)}
                                            />
                                            <Button variant="contained" color="primary" onClick={handleAddBarcode}>
                                                Add new barcode
                                            </Button>
                                        </Grid>
                                    </TagFormGrid>
                                </Grid>
                            }
                            <LoadingButton
                                loading={update.isLoading}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                style={{ flex: `end`, marginTop: `1.2em` }}>
                                {id ? `Update Product` : `Create Product`}
                            </LoadingButton>
                            {id &&
                                <Button
                                    component="label"
                                    children="Delete product"
                                    style={{ flex: `end`, marginTop: `1.2em` }}
                                    onClick={deleteProduct} />
                            }
                            {requestError && <p style={{ color: `red` }}>{requestError}</p>}
                        </Grid>
                    </Form>)}
            </Formik>
        </Fragment>
    );
};

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));