import { LoadingButton } from '@mui/lab';
import { Button, Grid, styled } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { toLocalISOString } from '../../../functions';
import { Discount, ProductCategory } from '../../../global';
import { createDiscount, deleteDiscountById, getDiscountById, updateDiscount } from '../../../setup/api/discounts';
import { getProductCategoriesDistinctOrdered, getProductCategoryByName } from '../../../setup/api/productCategory';
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { alertPayload, useFetchWithAlert } from "../../../setup/hooks/useFetchWithAlert";
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import SelectField from '../../../ui/components/FieldSelect/FieldSelectWithoutFormix';
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { SelectedProductCategoryTable } from '../../../ui/components/ProductCategoryTable/ProductCategoryTable';
import { ComparTextField } from "../../../ui/components/TextField/ComparTextField";
import { TitleHeading } from "../../../ui/components/Titles/Title";
import { parseCapitalStringToReadable, parseProductCategoryBack } from '../../../ui/helpers/stringModifiers';

interface CategoryItem {
    compar_name: string;
}

export const AddDiscountModal: FC = () => {
    const [discount, setDiscount] = useState<Partial<Discount>>({});

    const { id } = useParams<{ id: string }>();

    const create = useFetchWithAlert(alertPayload(`Discount created`));
    const update = useFetchWithAlert(alertPayload(`Discount updated`));
    const deleted = useFetchWithAlert(alertPayload(`Discount deleted`));

    const [loading, setLoading] = useState<boolean>(true);
    const [productCategories, setProductCategories] = useState<string[]>([]);
    const [selectedProductCategories, setSelectedProductCategories] = useState<ProductCategory[]>([]);
    const [productCategory, setProductCategory] = useState<Partial<ProductCategory> | ``>();

    const [requestError, setRequestError] = useState<string>(``);

    const { request, abortController } = useFetch();
    const history = useHistory();

    const handleSubmit = (values: any) => {
        setRequestError(``);

        const payload = {
            ...values,
            productCategory: selectedProductCategories
        };

        if (id) {
            update.request(updateDiscount, id, payload)
                .then(res => {
                    if (!res.id) {
                        setRequestError(res);
                    }
                })
                .catch(error => {
                    setRequestError(error);
                    console.log(error);
                });
        } else {
            create.request(createDiscount, payload)
                .then(res => {
                    if (res.id) {
                        history.replace(`/discount/${res.id}`);
                    } else {
                        setRequestError(res);
                    }
                })
                .catch(error => {
                    setRequestError(error);
                    console.log(error);
                });
        }
    };

    const deleteDiscountHandler = () => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text) === true) {
                deleted.request(deleteDiscountById, id).then(deleteDiscount => {
                    if (deleteDiscount.status === 200) {
                        history.replace(`/discounts`);
                    } else {
                        setRequestError(`Error message: ${deleteDiscount.message}`);
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (id) {
            request(getDiscountById, id)
                .then(res => {
                    setDiscount(res);
                    setSelectedProductCategories(res.productCategory);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [id]);

    useEffect(() => {
        try {
            request(getProductCategoriesDistinctOrdered, abortController.signal)
                .then((categories: CategoryItem[]) => {
                    setProductCategories(categories.map(item => item.compar_name));
                })
                .catch(error => {
                    console.error(`Error fetching categories:`, error);
                });
        } catch (error) {
            console.error(`Failed to fetch product categories`, error);
        }
    }, []);

    const appendSelectedProductCategory = () => {
        setLoading(true);
        const isCategoryAlreadySelected = selectedProductCategories.some(
            existingProductCategory => existingProductCategory.compar_name === productCategory
        );

        if (!isCategoryAlreadySelected) {
            request(getProductCategoryByName, productCategory, abortController.signal)
                .then(productCategory => {
                    if (productCategory.id) {
                        setSelectedProductCategories(prevArray => [...prevArray, productCategory]);
                        setProductCategory(``);
                        setLoading(false);
                    } else {
                        console.log(`Product Category not found`);
                    }
                })
                .catch(error => {
                    console.error(`Error fetching product category:`, error);
                });
        } else {
            console.log(`Already added to article`);
        }
    };

    return <ModalCompar
        open={true}
        onClose={() => {
            history.goBack();
        }}
        title={`Ingredient`}>
        <Formik
            initialValues={discount}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {(props: FormikProps<Partial<Discount>>) => (
                <Form
                    onChange={() => {
                        setRequestError(``);
                    }}
                >
                    <Grid container justifyContent={`space-between`} padding={1}>
                        <TagFormGrid item xs={12}>
                            <TitleHeading children="Discount information" style={{
                                fontSize: `1.2rem`,
                                paddingBottom: `1em`
                            }} />
                            <Grid xs={5}>
                                <SelectField
                                    name='type'
                                    label='Type'
                                    inputLabelStyle={{
                                        backgroundColor: `transparent`,
                                    }}
                                    style={{
                                        backgroundColor: `#FFF`,
                                        border: `none`,
                                    }}
                                    value={props.values.type ? { id: props.values.type, value: props.values.type } : null}
                                    onChange={value => {
                                        if (value) {
                                            props.setFieldValue(`type`, value.value);
                                        }
                                    }}
                                    options={[
                                        { id: `general`, value: `general` },
                                        { id: `code`, value: `code` },
                                    ]}
                                    getOptionLabel={option => option.value}
                                    width={`100%`}
                                />
                                {props.values.type === `code` &&
                                    <ComparTextField
                                        type='string'
                                        label='Code'
                                        required={true}
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder='Discount code'
                                        {...props.getFieldProps(`code`)}
                                    />
                                }
                                <ComparTextField
                                    type='number'
                                    label='Amount'
                                    required={true}
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Amount in whole %'
                                    inputProps={{
                                        min: 0,
                                        max: 100,
                                    }}
                                    value={props.values.amount}
                                    onChange={e => {
                                        let value = parseInt(e.target.value, 10);

                                        if (value < 0) {
                                            value = 0;
                                        }
                                        if (value > 100) {
                                            value = 100;
                                        }

                                        props.setFieldValue(`amount`, value);
                                    }}
                                />
                                <ComparTextField
                                    type="datetime-local"
                                    name="validUntill"
                                    label="Valid Until"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    value={props.values.validUntill ? toLocalISOString(new Date(props.values.validUntill)) : ``}
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    onChange={e => {
                                        const { value } = e.target;

                                        props.setFieldValue(`validUntill`, new Date(value));
                                    }}
                                />
                                {props.values.type === `general` &&
                                    <Grid>
                                        <Grid display="flex" alignItems="center" justifyContent="space-between" style={{
                                            width: `100%`
                                        }} >
                                            <Grid xs={5.7}>
                                                <SelectFieldFromArray<string>
                                                    name='productCategory'
                                                    label='Product Category'
                                                    keyName='productCategory'
                                                    required={false}
                                                    sx={{
                                                        marginRight: `1em`,
                                                        backgroundColor: `#000`,
                                                        borderRadius: `20px`,
                                                        'fieldset': {
                                                            border: `none`
                                                        }

                                                    }}
                                                    inputLabelStyle={{
                                                        backgroundColor: `transparent`,
                                                    }}

                                                    style={{
                                                        backgroundColor: `#fff`,
                                                    }}
                                                    onChange={(e: { target: { value: ProductCategory } }) => {
                                                        let string = e.target.value as any;

                                                        string = parseProductCategoryBack(string);

                                                        const joinedValue = string as ProductCategory;

                                                        setProductCategory(joinedValue);
                                                    }}
                                                    options={productCategories.map(parseCapitalStringToReadable)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button type="button"
                                            disabled={!productCategory}
                                            variant="contained"
                                            color="primary"
                                            children="Add Category"
                                            onClick={appendSelectedProductCategory}
                                            sx={{
                                                height: `3em`,
                                                width: `10em`,
                                                marginLeft: `1em`,
                                            }} />
                                        <Grid>
                                            <SelectedProductCategoryTable
                                                data={selectedProductCategories}
                                                objectsSelected={selectedProductCategories}
                                                setObjectSelected={setSelectedProductCategories}
                                                titleText='Selected Product Categories'
                                            />
                                        </Grid>
                                        <p>If no product categories are selected the discount is applied to all</p>
                                    </Grid>
                                }
                            </Grid>
                            <div style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`,
                                marginTop: `1.2em`
                            }}>
                                <LoadingButton
                                    loading={id ? update.isLoading : create.isLoading}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ flex: `end`, marginTop: `1em` }}
                                    children={id ? `Update discount` : `Create discount`}
                                    disabled={
                                        !props.values.type ||
                                        !props.values.amount ||
                                        !props.values.validUntill ||
                                        (props.values.type === `code` && !props.values.code) ||
                                        loading
                                    }
                                />
                                {id &&
                                    <Button
                                        component="label"
                                        children="Delete discount"
                                        onClick={deleteDiscountHandler}
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                    />
                                }
                                {requestError && <p style={{ color: `red` }}>{requestError}</p>}
                            </div>
                        </TagFormGrid>
                    </Grid>
                </Form>)}
        </Formik>
    </ModalCompar>;
};

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));