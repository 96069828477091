import { ComponentType } from 'react';

import { ArticlesPage } from '../pages/articles/ArticlesPage';
import { CreateArticlePage } from '../pages/articles/CreateArticlePage';
import { AddDiscountModal } from '../pages/discounts/AddDiscountPage';
import { DiscountsPage } from '../pages/discounts/DiscountsPage';
import { AddHairFeatureToIngredientPage } from '../pages/hairFeature/AddHairFeatureToIngredientsPage';
import { CreateIngredientModal } from '../pages/ingredient/CreateIngredientModal';
import { IngredientPage } from '../pages/ingredient/IngredientPage';
import { AddIngredientCategoryPage } from '../pages/ingredientCategories/AddIngredientCategoryPage';
import { IngredientCategoryPage } from '../pages/ingredientCategories/IngredientCategoryPage';
import { SendNotificationsPage } from '../pages/notifications/SendNotifications';
import { OrderDetailsPage } from '../pages/orders/OrderDetailsPage';
import { OrdersPage } from '../pages/orders/OrdersPage';
import { PendingProductModal } from '../pages/pending-product/PendingProductModal';
import { PendingProductPage } from '../pages/pending-product/PendingProductPage';
import { AddProductPage } from '../pages/product/AddProductPage';
import { ProductPage } from '../pages/product/ProductPage';
import { AddRetailerModal } from '../pages/retailer/AddRetailerPage';
import { RetailerPage } from '../pages/retailer/RetailerPage';
import { ScraperAdminPage } from '../pages/scrapers/ScraperAdminPage';
import { IngredientTagPage } from '../pages/tag/TagPage';
import { FeedbackModal } from '../pages/users/FeedbackModal';
import { ShowUserStatisticsPage } from '../pages/users/ShowUserStatisticsPage';
import { AddVoucherPage } from '../pages/vouchers/AddVoucherPage';
import { VouchersPage } from '../pages/vouchers/VouchersPage';

export const privateRoutes: [string, ComponentType<unknown>][] = [
    // If a id page is need remember to put above the create page
    [`/products`, ProductPage],
    [`/product/:id`, AddProductPage],
    [`/pending-products`, PendingProductPage],
    [`/pending-product/:id`, PendingProductModal],
    [`/product`, AddProductPage],
    [`/ingredients`, IngredientPage],
    [`/addIngredient`, CreateIngredientModal],
    [`/ingredient/:id`, IngredientPage],
    [`/addHairFeatures`, AddHairFeatureToIngredientPage],
    [`/tagIngredient/:id`, IngredientTagPage],
    [`/scrapers`, ScraperAdminPage],
    [`/articles`, ArticlesPage],
    [`/article/:id`, CreateArticlePage],
    [`/article`, CreateArticlePage],
    [`/users`, ShowUserStatisticsPage],
    [`/feedback/:id`, FeedbackModal],
    [`/sendNotifications`, SendNotificationsPage],
    [`/retailers`, RetailerPage],
    [`/retailer/:id`, AddRetailerModal],
    [`/retailer`, AddRetailerModal],
    [`/ingredientCategories`, IngredientCategoryPage],
    [`/ingredientCategory/:id`, AddIngredientCategoryPage],
    [`/ingredientCategory`, AddIngredientCategoryPage],
    [`/discounts`, DiscountsPage],
    [`/discount/:id`, AddDiscountModal],
    [`/discount`, AddDiscountModal],
    [`/orders`, OrdersPage],
    [`/order/:id`, OrderDetailsPage],
    [`/vouchers`, VouchersPage],
    [`/voucher/:id`, AddVoucherPage],
    [`/voucher`, AddVoucherPage],

    [`/`, ProductPage],
];

export const editorRoutes: [string, ComponentType<unknown>][] = [
    // If a id page is need remember to put above the create page
    [`/addHairFeatures`, AddHairFeatureToIngredientPage],
    [`/ingredients`, IngredientPage],
    [`/ingredient/:id`, IngredientPage],

    [`/`, AddHairFeatureToIngredientPage],
];

export const adminRoutes: [string, ComponentType<unknown>][] = [];