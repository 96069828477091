import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { formatDate, isDateInPast } from '../../../functions';
import { Voucher } from '../../../global';
import { getVoucherList } from '../../../setup/api/voucher';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { RootState } from '../../../setup/redux/RootReducer';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const VouchersPage: FC = () => {
    const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);

    const { request, isLoading, abortController } = useFetch();

    const [voucherList, setVoucherList] = useState();

    const queriesFromRouter = useLocation().search;
    const [queryParams, setQueryParams] = useState(queriesFromRouter);

    useEffect(() => {
        request(getVoucherList, queryParams, abortController.signal).then(res => {
            if (res) {
                setVoucherList(res);
            }
        });
        return () => {
            abortController.abort();
        };
    }, [queryParams]);

    interface SortConfig {
        key: string | null;
        direction: `ASC` | `DESC`;
    }

    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: `validUntill`, direction: `DESC` });

    const handleSort = (key: string) => {
        let direction: `ASC` | `DESC` = `ASC`;

        if (sortConfig.key === key && sortConfig.direction === `ASC`) {
            direction = `DESC`;
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(queriesFromRouter);

        if (sortConfig.key) {
            searchParams.set(`sortKey`, sortConfig.key);
            searchParams.set(`sortDirection`, sortConfig.direction);
        }

        setQueryParams(`? ${searchParams.toString()}`);
    }, [queriesFromRouter, sortConfig]);

    return <div className='mt-5 me-5' style={{ marginBottom: `2em` }}>
        <Grid container xs={12} marginTop={3} marginBottom={3}>
            <Grid xs={12} marginTop={2} sx={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `right`,
            }}>
                {userRole === `admin` && <RenderCreateVoucherButton />}
            </Grid>
        </Grid>
        {
            voucherList && <Datagrid<Voucher>
                data={voucherList}
                headings={voucherHeadings}
                link='voucher'
                loading={isLoading}
                renderRow={renderRows}
                handleSort={handleSort}
                sortConfig={sortConfig}
            />
        }
    </div >;
};

const RenderCreateVoucherButton: FC = () => (
    <Link to='/voucher' style={{ textAlign: `center` }}>
        <Button
            color="primary"
            variant="contained"
            children="Create New Voucher" />
    </Link>
);

export const voucherHeadings: Array<[string, string]> = [
    [`user`, `User`],
    [`amount`, `Amount (EUR)`],
    [`validUntill`, `Valid Untill`],
];

type Heading = [string, string];

interface VoucherPartial {
    id: string;
    [key: string]: unknown;
}

function renderRows(
    headings: Heading[],
    item: VoucherPartial,
) {
    return headings.map(([key]) => {
        if (key === `user`) {
            const user = item[key] as any;

            return (
                <td key={key}>
                    {user ? `${user?.firstName ?? ``} ${user?.lastName ?? ``}` : `No user`}
                </td>
            );
        }
        if (key === `validUntill`) {
            const isExpired = isDateInPast(item[key] as string);

            return <td key={key} style={{ color: isExpired ? `#C41E3A` : `inherit` }}>{formatDate(item[key] as string)}</td>;
        }
        return <td key={key}>{(item[key] as string)}</td>;
    });
}