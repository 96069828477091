import config from 'react-global-configuration';

export const getOrdersList = (queries: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/order${queries ? queries : ``}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getOrderById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/order/${id}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const updateOrder = (id: string, payload: any): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/order/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});