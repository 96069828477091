import config from 'react-global-configuration';

import { Voucher } from '../../global';

export const getVoucherList = (queries: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/voucher${queries ? queries : ``}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const getVoucherById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/voucher/${id}`, {
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});

export const createVoucher = (payload: Partial<Voucher>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/voucher`, {
    method: `POST`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const updateVoucher = (id: string, payload: Partial<Voucher>): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/voucher/${id}`, {
    method: `PUT`,
    mode: `cors`,
    credentials: `include`,
    headers: {
        'Content-Type': `application/json`,
    },
    body: JSON.stringify(payload),
});

export const deleteVoucherById = (id: string, abort: AbortSignal): Promise<Response> => fetch(`${config.get(`ApiUrl`).Rest}/article/${id}`, {
    method: `DELETE`,
    mode: `cors`,
    credentials: `include`,
    signal: abort,
});