import { LoadingButton } from '@mui/lab';
import { Button, Grid, styled } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { toLocalISOString } from '../../../functions';
import { Voucher } from '../../../global';
import { getUsersForVoucher } from '../../../setup/api/user';
import { createVoucher, deleteVoucherById, getVoucherById, updateVoucher } from '../../../setup/api/voucher';
import { useFetch } from "../../../setup/hooks/fetch.hook";
import { alertPayload, useFetchWithAlert } from "../../../setup/hooks/useFetchWithAlert";
import SelectField from '../../../ui/components/FieldSelect/FieldSelectWithoutFormix';
import { ModalCompar } from "../../../ui/components/Modal/ModalCompar";
import { ComparTextField } from "../../../ui/components/TextField/ComparTextField";
import { TitleHeading } from "../../../ui/components/Titles/Title";

export const AddVoucherPage: FC = () => {
    const [voucher, setVoucher] = useState<Partial<Voucher>>({});

    const { id } = useParams<{ id: string }>();

    const create = useFetchWithAlert(alertPayload(`Voucher created`));
    const update = useFetchWithAlert(alertPayload(`Voucher updated`));
    const deleted = useFetchWithAlert(alertPayload(`Voucher deleted`));

    const [user, setUser] = useState<{ id: string, value: string }>();
    const [users, setUsers] = useState<{ id: string, value: string }[]>([]);

    const [requestError, setRequestError] = useState<string>(``);

    const { request } = useFetch();
    const history = useHistory();

    const handleSubmit = (values: any) => {
        if (user) {
            setRequestError(``);

            const payload = {
                ...values,
                userId: user.id
            };

            if (id) {
                update.request(updateVoucher, id, payload)
                    .then(res => {
                        if (!res.id) {
                            setRequestError(res);
                        }
                    })
                    .catch(error => {
                        setRequestError(error);
                        console.log(error);
                    });
            } else {
                create.request(createVoucher, payload)
                    .then(res => {
                        if (res.id) {
                            console.log(res);
                            history.replace(`/voucher/${res.id}`);
                        } else {
                            setRequestError(res.message);
                        }
                    })
                    .catch(error => {
                        setRequestError(error);
                        console.log(error);
                    });
            }
        } else {
            setRequestError(`Please select a user`);
        }
    };

    const deleteVoucherHandler = () => {
        if (id) {
            const text = `This cannot be undone. Are you sure?`;

            if (confirm(text) === true) {
                deleted.request(deleteVoucherById, id).then(deleteVoucher => {
                    if (deleteVoucher.status === 200) {
                        history.replace(`/vouchers`);
                    } else {
                        setRequestError(`Error message: ${deleteVoucher.message}`);
                    }
                });
            }
        }
    };

    useEffect(() => {
        request(getUsersForVoucher)
            .then(res => {
                console.log(res);
                setUsers(res);
            });

        if (id) {
            request(getVoucherById, id)
                .then(res => {
                    setVoucher(res);
                    setUser(res.user);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [id]);

    return <ModalCompar
        open={true}
        onClose={() => {
            history.goBack();
        }}
        title={`Ingredient`}>
        <Formik
            initialValues={voucher}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {(props: FormikProps<Partial<Voucher>>) => (
                <Form
                    onChange={() => {
                        setRequestError(``);
                    }}
                >
                    <Grid container justifyContent={`space-between`} padding={1}>
                        <TagFormGrid item xs={12}>
                            <TitleHeading children="Voucher information" style={{
                                fontSize: `1.2rem`,
                                paddingBottom: `1em`
                            }} />
                            <Grid xs={5}>
                                <SelectField
                                    name='user'
                                    label='User'
                                    inputLabelStyle={{
                                        backgroundColor: `transparent`,
                                    }}
                                    style={{
                                        backgroundColor: `#FFF`,
                                        border: `none`,
                                    }}
                                    onChange={value => {
                                        if (value) {
                                            setUser({ id: value.id, value: value.value });
                                        }
                                    }}
                                    options={users}
                                    getOptionLabel={option => option.value}
                                    width={`100%`}
                                    value={user ? user : null}
                                />
                                <ComparTextField
                                    type='number'
                                    label='Amount'
                                    required={true}
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Amount in EUR'
                                    value={props.values.amount}
                                    onChange={e => {
                                        props.setFieldValue(`amount`, e.target.value);
                                    }}
                                />
                                <ComparTextField
                                    type="datetime-local"
                                    name="validUntill"
                                    label="Valid Until"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    value={props.values.validUntill ? toLocalISOString(new Date(props.values.validUntill)) : ``}
                                    style={{
                                        backgroundColor: `#FFF`,
                                    }}
                                    onChange={e => {
                                        const { value } = e.target;

                                        props.setFieldValue(`validUntill`, new Date(value));
                                    }}
                                />
                            </Grid>
                            <div style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`,
                                marginTop: `1.2em`
                            }}>
                                <LoadingButton
                                    loading={id ? update.isLoading : create.isLoading}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ flex: `end`, marginTop: `1em` }}
                                    children={id ? `Update voucher` : `Create voucher`}
                                    disabled={
                                        !user ||
                                        !props.values.amount ||
                                        !props.values.validUntill
                                    }
                                />
                                {id &&
                                    <Button
                                        component="label"
                                        children="Delete voucher"
                                        onClick={deleteVoucherHandler}
                                        style={{
                                            backgroundColor: `#FFF`,
                                        }}
                                    />
                                }
                                {requestError && <p style={{ color: `red` }}>{requestError}</p>}
                            </div>
                        </TagFormGrid>
                    </Grid>
                </Form>)}
        </Formik>
    </ModalCompar>;
};

const TagFormGrid = styled(Grid)(() => ({
    backgroundColor: `#F7F5F3`,
    paddingLeft: `3em`,
    paddingRight: `3em`,
    paddingTop: `2em`,
    paddingBottom: `2em`,
    borderRadius: `20px`,
}));