import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { formatDate, isDateInPast } from '../../../functions';
import { getOrdersList } from '../../../setup/api/orders';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../ui/components/DataGrid/DataGrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const OrdersPage: FC = () => {
    const { request, isLoading, abortController } = useFetch();

    const [orderCount, setOrderCount] = useState(0);
    const [orderList, setOrderList] = useState<[]>([]);

    const queriesFromRouter = useLocation().search;
    const [queryParams, setQueryParams] = useState(queriesFromRouter);

    useEffect(() => {
        request(getOrdersList, queryParams, abortController.signal).then(res => {
            if (res) {
                setOrderList(res.results);
                setOrderCount(res.totalPages * 10);
            }
        });
        return () => {
            abortController.abort();
        };
    }, [queryParams]);

    interface SortConfig {
        key: string | null;
        direction: `ASC` | `DESC`;
    }

    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: `createdAt`, direction: `DESC` });

    const handleSort = (key: string) => {
        let direction: `ASC` | `DESC` = `ASC`;

        if (sortConfig.key === key && sortConfig.direction === `ASC`) {
            direction = `DESC`;
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(queriesFromRouter);

        if (sortConfig.key) {
            searchParams.set(`sortKey`, sortConfig.key);
            searchParams.set(`sortDirection`, sortConfig.direction);
        }

        setQueryParams(`?${searchParams.toString()}`);
    }, [queriesFromRouter, sortConfig]);

    return <div className='mt-5 me-5' style={{ marginBottom: `2em` }}>
        <Grid container xs={12} marginBottom={3}>
            <Grid xs={12}>
                <FilterComponent
                    count={orderCount}
                    entity='order'
                    showPagination
                />
            </Grid>
        </Grid>
        {
            orderList && <Datagrid
                data={orderList}
                headings={orderHeadings}
                link='order'
                loading={isLoading}
                renderRow={renderRows}
                handleSort={handleSort}
                sortConfig={sortConfig}
            />
        }
    </div >;
};

export const orderHeadings: Array<[string, string]> = [
    [`user`, `Ordered By`],
    [`totalAmount`, `Total Amount`],
    [`currency`, `Currency`],
    [`totalLocalAmount`, `Local Total`],
    [`localCurrency`, `Local Currency`],
    [`status`, `Status`],
    [`order_id`, `Big Buy Order Id`],
    [`createdAt`, `Created At`],
];

type Heading = [string, string];

interface OrderPartial {
    id: string;
    [key: string]: unknown;
}

function renderRows(
    headings: Heading[],
    item: OrderPartial,
) {
    return headings.map(([key]) => {
        if (key === `createdAt`) {
            return <td key={key}>{formatDate(item[key] as string)}</td>;
        }
        if (key === `user`) {
            const user = item[key] as any;

            return (
                <td key={key}>
                    {user ? `${user?.firstName ?? ``} ${user?.lastName ?? ``}` : `No user`}
                </td>
            );
        }
        return <td key={key}>{(item[key] as string)}</td>;
    });
}